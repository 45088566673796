<template>
  <!-- eslint-disable-next-line vue/camelcase -->
  <el-menu-item :key="alpha_3_code" :index="alpha_3_code">
    <nuxt-link
      :to="{
        name: 'search-type',
        params: {
          type: $t('router.search_type.type.country'),
          value: slug,
          tab: $t('router.search_type.tab.tours'),
        },
      }"
      @click.native="handleLoading"
    >
      {{ name }}
    </nuxt-link>
  </el-menu-item>
</template>

<script>
export default {
  name: 'LadDestinationSubmenuItem',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    alpha_3_code: { type: String, required: true },
    slug: { type: String, required: true },
    name: { type: String, required: true },
  },
  methods: {
    handleLoading() {
      this.$nuxt.$emit('custom-loading', { enabled: true, source: 'search' })
    },
  },
}
</script>
